
import AppInstanceConfigApi, {
  AppInstanceConfig
} from '@/api/maintain/app-instance-config'
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import { Component, Vue, Watch } from 'vue-property-decorator'
import AppDefaultConfigApi from '@/api/maintain/app-default-config'
import PcServerSelectorInApp from './selector/pc-server-selector-in-app.vue'
import ConfigForm from '@/components/application/config-form.vue'
import { BaseConfigType, BaseConfig } from '@/api/maintain/base-config'
import AppInstanceNginxServerApi, { AppInstanceNginxServer } from '@/api/maintain/app-instance-nginx-server'
import AppInstanceNginxConfigApi, { AppInstanceNginxConfig } from '@/api/maintain/app-instance-nginx-config'
import ImagesResourceApi, { ImageType } from '@/api/maintain/image-resource'
import showAddNginxConfigModal from '../components/modal/show-add-nginx-config-modal'
import { AttachmentFileData } from '@/api/maintain/attachment-filedata'
import { Form } from 'view-design'
import _ from 'lodash'
import UploadButton from '@/components/upload-button.vue'
import { alertList } from '@/views/maintain/image-resource/alert'

@Component({
  name: 'StandardContainer',
  components: {
    PcServerSelectorInApp,
    ConfigForm,
    UploadButton,
    Component
  }
})
export default class StandardContainer extends Vue {
  // 实例id
  id = 0;
  // 实例数据
  instanceData = {} as AppInstance;
  // 页面模式，默认为修改,新增时配置项数据暂存于页面，不立即修改
  pageMode = 'edit';
  /// 保存按钮加载状态
  loading = false;
  /** 实例配置数据 */
  instanceConfigData: BaseConfig[] = [];
  /** 判断是否为nginx镜像资源 */
  isNginxImage = false
  nginxDataList = [] as AppInstanceNginxServer[]
  /** 当前选中的nginx对象 */
  curNginxData = { useSsl: false } as AppInstanceNginxServer
  /** 当前选中的nginx对象的config值 */
  curNginxConfigList = [] as AppInstanceConfig[]
  /** 当前表格选中的数据 */
  selectionData = [] as AppInstanceNginxConfig[]
  /** 上传等待 */
  uploadLoading = false
  /** 提示组件 */
  alertList = alertList
  /** 是否在编辑模式下使用了应用模版 */
  templateUseFlag = false
  /** 剩余内存 */
  remainingMemory = -1
  /** 是否为复制 */
  isCopy=false

  columns = [
    { type: 'selection', width: 60, align: 'center' },
    { title: 'location', key: 'locationUri' },
    { title: '类型', slot: 'type' },
    { title: 'path', key: 'path' },
    { title: '备注', key: 'memo' },
    { title: '操作', slot: 'action' }
  ]

  /// 表单校验规则
  rules = {
    pcServerId: [
      { type: 'number', required: true, message: '服务器不能为空', trigger: 'change' }
    ],
    name: [{ required: true, message: '实例名称不能为空', trigger: 'blur' }, {
      pattern: /^[a-zA-Z][A-Za-z0-9_-]*$/,
      trigger: 'blur',
      message: '请输入英文字母与数字或下划线的组合，必须英文字母开头'
    }],
    containerName: [{ required: true, message: '实例容器名称不能为空', trigger: 'blur' }, {
      pattern: /^[a-z][a-z0-9_-]*$/,
      trigger: 'blur',
      message: '请输入小写英文字母与数字或下划线的组合，必须英文字母开头'
    }]
  }

  /** nginxServer校验规则 */
  nginxRules = {
    name: [
      { required: true, message: '名称不允许为空', trigger: 'blur' }
    ]
  }

  /** 上传等待事件 */
  onLoading (val: boolean) {
    this.uploadLoading = val
  }

  /** 上传文件成功 */
  uploadCrtOk (val: AttachmentFileData) {
    this.$set(this.curNginxData, 'sslCertPath', `attr:${val.id}`)
  }

  /** 上传文件成功 */
  uploadKeyOk (val: AttachmentFileData) {
    this.$set(this.curNginxData, 'sslPrivateKeyPath', `attr:${val.id}`)
  }

  @Watch('isNginxImage')
  onIsNginxImage () {
    if (this.isNginxImage) {
      /** 获取nginxServer信息 */
      this.loadNginxServer()
    }
  }

  @Watch('instanceData.pcServerId', { deep: true, immediate: true })
  onPcServerId () {
    if (this.instanceData.pcServerId) {
      AppInstanceApi.getPcServerRemainingMemory(this.instanceData.pcServerId, this.instanceData.id || 0).then((res: any) => {
        this.remainingMemory = res.data
        this.$Message.success('当前服务器剩余内存查询成功!')
      }).catch((err: any) => {
        this.$Message.error('当前服务器剩余内存查询失败: ' + err)
      })
    }
  }

  get componentName () {
    const alerts = this.instanceData.defaultConfigAlerts?.split(',').map(item => {
      const data = item.split(':')
      if (data[0][0] === '*') data[0] = data[0].substring(1)
      return data
    }) || []
    for (let i = 0; i < alerts.length; i++) {
      if (alerts[i][0] === this.instanceData.configTemplateName) {
        return alerts[i][1]
      }
    }
    return ''
  }

  /** 判断卡片class样式 */
  checkCardClass (id: number | undefined) {
    if (id) {
      if (this.curNginxData.id === id) return 'actived'
      return 'card'
    } return 'actived'
  }

  /** 新增nginxServer */
  addServer () {
    this.curNginxData = { useSsl: false }
  }

  /** 新增/修改nginxConfig */
  addNginxConfig (data: AppInstanceConfig) {
    showAddNginxConfigModal(this.id, this.curNginxData.id!, data).then(() => {
      this.loadNginxConfig(this.curNginxData.id!)
    })
  }

  /** 选中nginxServer对象 */
  clickNginxServer (index: number) {
    this.curNginxData = this.nginxDataList[index]
    this.loadNginxConfig(this.curNginxData.id || 0)
  }

  /** 表格选中的值 */
  selectTableData (selection: AppInstanceNginxConfig[]) {
    this.selectionData = selection
  }

  /** 保存nginxServer对象 */
  saveServer () {
    if (
      this.curNginxData.useSsl &&
      (!this.curNginxData.sslCertPath ||
      !this.curNginxData.sslPrivateKeyPath)) {
      this.$Message.error('开启ssl后必须上传crt证书和key证书')
    } else {
      (this.$refs.nginxServerForm as Form).validate((result) => {
        if (result) {
          this.curNginxData.nginxInstanceId = this.id
          /** 有id说明为修改 */
          if (this.curNginxData.id) {
            AppInstanceNginxServerApi.updateItem(this.curNginxData).then(() => {
              this.$Notice.success({ title: '成功', desc: '保存成功' })
              this.loadNginxServer()
            }).catch((err) => {
              this.$Notice.error({ title: '失败', desc: err.message })
            })
          } else {
            /** 新增 */
            AppInstanceNginxServerApi.insertItem(this.curNginxData).then(() => {
              this.$Notice.success({ title: '成功', desc: '保存成功' })
              this.loadNginxServer()
            }).catch((err) => {
              this.$Notice.error({ title: '失败', desc: err.message })
            })
          }
        } else {
          this.$Notice.warning({
            title: '警告',
            desc: '数据校验失败'
          })
        }
      })
    }
  }

  /** 加载nginxServer */
  loadNginxServer () {
    AppInstanceNginxServerApi.getItemsByNginxInstanceId(this.id).then((res) => {
      this.nginxDataList = res.data || []
    })
  }

  /** 加载nginx配置 */
  loadNginxConfig (id: number) {
    AppInstanceNginxConfigApi.getItemsByNginxServerId(id).then((res) => {
      this.curNginxConfigList = res.data || []
    })
  }

  /** 获取nginxConfigType */
  getNginxConfigTypeName (type: number) {
    switch (type) {
      case 1:
        return 'root'
      case 2:
        return 'alias'
      case 3:
        return 'proxy_pass'
    }
  }

  /** 设置实例名 */
  setInstanceName (val: string) {
    this.$set(this.instanceData, 'name', val)
    if (this.pageMode === 'new') { this.$set(this.instanceData, 'containerName', _.lowerFirst(val).replace(/[A-Z]+/g, (val) => `_${val.toLowerCase()}`)) }
    this.instanceData.name = val
  }

  /** 删除nginxConfig */
  deleteNginxConfig (id?: number) {
    if (!id && this.selectionData.length === 0) {
      this.$Message.warning('请先选中要删除的数据')
    } else {
      const message = id ? '确认要删除当前数据' : `确认要删除${this.selectionData.length}条数据`
      this.$Modal.confirm({
        title: '确认',
        content: `<p>${message}</p>`,
        onOk: () => {
          AppInstanceNginxConfigApi.deleteItemByIds(id ? [id] : this.selectionData.map((item) => item.id!))
            .then(() => {
              this.loadNginxConfig(this.curNginxData.id || 0)
              this.$Notice.success({ title: '成功', desc: '删除成功' })
            })
            .catch((err) => {
              this.$Notice.error({ title: '失败', desc: err.message })
            })
        }
      })
    }
  }

  /** 删除server配置 */
  deleteServer (id: number) {
    this.$Modal.confirm({
      title: '确认',
      content: '<p>确认要删除当前数据！</p>',
      onOk: () => {
        AppInstanceNginxServerApi.deleteById(id)
          .then(() => {
            this.loadNginxServer()
            this.curNginxData = { useSsl: false }
            this.$Notice.success({ title: '成功', desc: '删除成功' })
          })
          .catch((err) => {
            this.$Notice.error({ title: '失败', desc: err.message })
          })
      }
    })
  }

  /// 修改模式初始化数据
  initForUpdate () {
    AppInstanceApi.getItemById(this.id).then((response) => {
      this.instanceData = response.data as AppInstance
      /** 判断是不是镜像资源，再判断是不是nginx资源 */
      if (response.data?.imageId) {
        ImagesResourceApi.getItemById(response.data.imageId).then((res) => {
          this.isNginxImage = res.data?.imageType === ImageType.NGINX
        })
      }
    })
    this.pageMode = 'edit'
    this.$nextTick(() => {
      (this.$refs.configForm as any).getConfigData()
    })
  }

  /// 初始化新增模式数据
  initForNew () {
    /// 从路由参数中获取实例基本信息
    const query = this.$route.query
    this.instanceData.applicationId = parseInt(query.appId as string)
    this.instanceData.applicationName = query.appName as string
    this.instanceData.applicationType = parseInt(query.appType as string)
    this.instanceData.applicationTypeTitle = query.appTypeName as string
    this.instanceData.defaultConfigAlerts = query.defaultConfigAlerts as string
    // 判断默认值
    const configFiles = (query.defaultConfigFile as string)?.split(',') || []
    for (let i = 0; i < configFiles.length; i++) {
      const fileData = configFiles[i].split(':')
      if (fileData[0][0] === '*') {
        this.$set(this.instanceData, 'configTemplateName', fileData[0].substring(1))
        break
      }
    }

    if (query.imageType) {
      this.instanceData.imageType = parseInt(query.imageType as string)
    }
    this.pageMode = 'new'
    AppDefaultConfigApi.getApplicationDefaultConfigList(parseInt(query.appId as string), this.instanceData.configTemplateName).then((res) => {
      this.instanceConfigData = res.data as BaseConfig[]
      (this.$refs.configForm as any).getConfigData()
    })
  }

  activated () {
    const copy = this.$route.params.copy
    if (copy === 'true') {
      this.id = parseInt(this.$route.params.id)
      this.pageMode = 'new'
      this.isCopy = true
      AppInstanceApi.getItemById(this.id).then((response) => {
        this.instanceData = {
          applicationId: response.data?.applicationId,
          applicationName: response.data?.applicationName,
          applicationType: response.data?.applicationType,
          applicationTypeTitle: response.data?.applicationTypeTitle,
          defaultConfigAlerts: response.data?.defaultConfigAlerts,
          imageType: response.data?.imageType,
          pcServerId: response.data?.pcServerId,
          containerName: `${response.data?.containerName}_copy`,
          name: `${response.data?.name}_copy`
        } as AppInstance
      })
      this.$nextTick(() => {
        (this.$refs.configForm as any).getConfigData()
      })
    } else {
      this.isCopy = false
      if (this.$route.params.id.match(/^\d+$/)) {
        this.id = parseInt(this.$route.params.id)
        this.initForUpdate()
      } else {
        this.initForNew()
      }
    }
  }

  chooseTemplateName (templateName: string) {
    if (this.pageMode === 'new') {
      const query = this.$route.query
      AppDefaultConfigApi.getApplicationDefaultConfigList(parseInt(query.appId as string), templateName)
        .then((res) => {
          this.$set(this.instanceData, 'configTemplateName', templateName)
          this.instanceConfigData = res.data as BaseConfig[]
          (this.$refs.configForm as any).getConfigData()
        })
        .catch((err) => {
          this.$Message.error('应用模版失败: ' + err)
        })
    } else {
      AppDefaultConfigApi.getApplicationDefaultConfigList(this.instanceData.applicationId, templateName)
        .then((res) => {
          this.$set(this.instanceData, 'configTemplateName', templateName)
          this.instanceConfigData = res.data as BaseConfig[]
          this.templateUseFlag = true
          ;(this.$refs.configForm as any).getConfigData()
        })
        .catch((err) => {
          this.$Message.error('应用模版失败: ' + err)
        })
    }
  }

  /** 加载数据 */
  loadFromServer () {
    if (this.isCopy) {
      return new Promise((resolve) => {
        AppInstanceConfigApi.queryByInstanceId(this.id).then((res) => {
            res.data?.forEach((e) => {
              e.id == null
              if (e.configType === BaseConfigType.PORT || e.hiddenValue) {
                e.mustModi = true
              }
            })
            resolve(res)
        })
      })
    }
    if (this.pageMode === 'edit' && !this.templateUseFlag) {
      return AppInstanceConfigApi.queryByInstanceId(
        this.id
      )
    }
    return new Promise((resolve) => {
      resolve({
        data: this.instanceConfigData
      })
    })
  }

  /** 删除数据 */
  onDelete (data: BaseConfig[]) {
    if (this.pageMode === 'edit' && !this.templateUseFlag) {
      return AppInstanceConfigApi.deleteItemByIds(data.map((e) => e.id!))
    }
    this.instanceConfigData = this.instanceConfigData.filter((ele: BaseConfig) => data.indexOf(ele) < 0)
    return new Promise((resolve) => {
      resolve(true)
    })
  }

  /** 修改配置值 */
  updateValue (configId: number, value: string, configKey?: string) {
    if (this.pageMode === 'edit' && !this.templateUseFlag) {
      return AppInstanceConfigApi.updateValueById(configId, value)
    } else {
      return new Promise((resolve) => {
        if (configKey) {
          const index = this.instanceConfigData.findIndex(item => item.configKey === configKey)
          this.instanceConfigData[index].mustModi = false
          this.instanceConfigData[index].configValue = value
        }

        resolve({
          data: {
            mustModi: false,
            configValue: value
          }
        })
      })
    }
  }

  /** 新增数据 */
  onSave (newValue: BaseConfig, oldValue: BaseConfig) {
    if (this.pageMode === 'edit' && !this.templateUseFlag) {
      if (newValue.id) {
        return AppInstanceConfigApi.updateItem(newValue as AppInstanceConfig)
      }
      return AppInstanceConfigApi.insertItem({ ...newValue, appInstanaceId: this.id } as AppInstanceConfig)
    }
    const index = this.instanceConfigData.indexOf(oldValue)
    if (index > -1) {
      if (newValue.configValue !== oldValue.configValue) {
        newValue.mustModi = false
      }
      this.instanceConfigData.splice(index, 1, newValue)
    } else {
      this.instanceConfigData.push(newValue)
    }
    newValue.mustModi = false
    return new Promise((resolve) => {
      resolve({
        data: newValue
      })
    })
  }

  /// 重置数据
  doReset () {
    this.initForNew()
  }

  /**
   * 保存实例修改
   */
  saveInstance () {
    AppInstanceApi.updateAppInstanceName(this.instanceData.id!, this.instanceData.name, this.instanceData.containerName).then((res) => {
      this.instanceData = res.data!
      this.$Message.success('保存成功')
    }).catch((err) => {
      this.$Message.error(err.message)
    })
  }

  /// 保存数据
  doSave () {
    if (this.instanceConfigData.filter((e) => e.mustModi).length > 0) {
      this.$Message.error('请先修改配置中必需修改项目')
      return
    }
    this.loading = true;
    (this.$refs.formInline as any).validate((result: boolean) => {
      if (result) {
        this.saveConfigs()
      } else {
        this.loading = false
        this.$Notice.warning({
          title: '警告',
          desc: '数据校验失败'
        })
      }
    })
  }

  /**
   * 保存配置
   */
  saveConfigs (datas?: BaseConfig[]) {
    const items = this.instanceConfigData.map((e) => {
      return {
        configType: BaseConfigType[e.configType!] as any,
        ...e
      } as BaseConfig
    })
    if (this.templateUseFlag && this.pageMode === 'edit') {
      AppInstanceApi.saveAndDeleteOldData({ configs: this.instanceConfigData, instance: this.instanceData })
        .then(() => {
          this.$Notice.success({
            title: '成功',
            desc: '操作成功'
          })
          this.templateUseFlag = false
          ;(this.$refs.configForm as any).getConfigData()
        })
    } else {
      AppInstanceApi.saveInstanceAndConfigs({
        appId: this.instanceData.applicationId,
        pcServerId: this.instanceData.pcServerId,
        instanceName: this.instanceData.name,
        containerName: this.instanceData.containerName,
        configTemplateName: this.instanceData.configTemplateName,
        defaultConfigAlerts: this.instanceData.defaultConfigAlerts,
        configs: items.concat(datas || []).map(item => {
          return {
            ...item,
            configType: BaseConfigType[item.configType!]
          }
        }) as any
      }).then((res) => {
        this.loading = false
        if (res.data?.id) {
          this.id = res.data?.id
          this.initForUpdate()
          this.$Notice.success({
            title: '成功',
            desc: '操作成功'
          })
          ;(this.$refs.bodyArea as Element).parentElement!.scrollTo(0, 0)
          this.pageMode = 'edit'
        }
      })
        .catch((err) => {
          this.loading = false
          this.$Notice.error({
            title: '错误',
            desc: err.message
          })
        })
    }
  }

  close () {
    const path = this.$route.params.from
    this.$router.push(path)
  }
}

import { AppInstanceConfig } from '@/api/maintain/app-instance-config'
import { showModal } from '@/libs/modal-helper'
import modal from './add-nginx-config-modal.vue'

export default function showAddInstanceModal (appInstanceId: number, nginxServerId: number, nginxConfigData: AppInstanceConfig): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {
      props: {
        appInstanceId,
        nginxServerId,
        nginxConfigData
      }
    }, true, () => {
      resolve()
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}


import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { Form } from 'view-design'
import appInstanceNginxConfig, { AppInstanceNginxConfig } from '@/api/maintain/app-instance-nginx-config'
import { PropType } from 'vue'

@Component({
  name: 'addNginxConfigModal',
  components: {}
})
export default class AddNginxConfigModal extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  appInstanceId!: number

  @Prop({
    type: Number,
    required: true
  })
  nginxServerId!: number

  @Prop({
    type: Object as PropType<AppInstanceNginxConfig>,
    required: true
  })
  nginxConfigData!: AppInstanceNginxConfig

  isShow = false

  formRules = {
    name: [
      {
        trigger: 'blur',
        required: true,
        message: '请输入实例名称'
      }
    ]
  }

  loading = false

  /**
   * 当前表单数据
   */
  addNginxConfigData: AppInstanceNginxConfig = {} as AppInstanceNginxConfig

  @Watch('nginxConfigData')
  onNginxConfigData () {
    this.addNginxConfigData = this.nginxConfigData
  }

  mounted () {
    this.addNginxConfigData = this.nginxConfigData
  }

  show () {
    this.isShow = true
  }

  close () {
    this.isShow = false
  }

  doCancel () {
    this.$emit('onCancel')
  }

  doOk () {
    this.$emit('onOk')
  }

  /// 提交
  submit () {
    const checkform = this.$refs.addAppForm as Form
    checkform.validate((valid) => {
      if (valid) {
        this.loading = true
        this.saveAppData()
      }
    })
  }

  /// 执行保存
  saveAppData () {
    /** 如果有id说明为修改 */
    if (this.nginxConfigData.id) {
      appInstanceNginxConfig.updateItem(this.addNginxConfigData)
        .then(() => {
          this.$Notice.success({ title: '成功', desc: '添加配置成功' })
          this.$emit('onOk')
        })
        .catch((err) => {
          this.$Notice.error({ title: '错误', desc: err.message })
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      /** 新增 */
      this.addNginxConfigData.nginxServerId = this.nginxServerId
      appInstanceNginxConfig.insertItem(this.addNginxConfigData)
        .then(() => {
          this.$Notice.success({ title: '成功', desc: '添加配置成功' })
          this.$emit('onOk')
        })
        .catch((err) => {
          this.$Notice.error({ title: '错误', desc: err.message })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}

import { render, staticRenderFns } from "./standard-container.vue?vue&type=template&id=a124be7c&scoped=true&"
import script from "./standard-container.vue?vue&type=script&lang=ts&"
export * from "./standard-container.vue?vue&type=script&lang=ts&"
import style0 from "./standard-container.vue?vue&type=style&index=0&id=a124be7c&prod&lang=less&scoped=true&"
import style1 from "./standard-container.vue?vue&type=style&index=1&id=a124be7c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a124be7c",
  null
  
)

export default component.exports